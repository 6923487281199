import React from 'react';

import styled from 'styled-components';

import { Input } from 'lib/Input';
import { Divider } from 'lib/Divider';
import { Select, SelectOption } from 'lib/Select';
import { InfoBlock } from '../../styled-subcomponents/InfoBlock';
import { EducationInfo } from './EducationInfo';
import { Institution, Campus, UserType } from 'models/listing';
import { OccupationInfo } from './OccupationInfo';
import { StepTitle } from '../../styled-subcomponents/StepTitle';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { InfoText } from '../../styled-subcomponents/InfoText';
import { LoginPromptContainer, LoginLink } from '../../styled-subcomponents/LoginText';
import { SubTitle } from '../../styled-subcomponents/SubTitle';
import { Checkbox } from 'lib/Checkbox';
import { Link } from 'react-router-dom';
import routes from 'config/routes';
import theme from 'config/theme';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';

const { colors } = theme;

const CheckBoxText = styled.p`
	font-size: 14px;
`;

const CheckboxText = styled.p<{ isError?: boolean }>`
	font-size: 14px;
	color: ${({ isError }) => (isError ? colors.red : colors.darkBlue)};
`;

const StyledLink = styled(Link)`
	text-decoration: underline;
	color: ${colors.darkBlue};
`;

const BoldText = styled(CheckBoxText)`
	font-weight: 700;
	padding-bottom: 16px;
`;

const Section = styled.div`
	margin-top: 24px;
`;

const PrivacyContainer = styled.div`
	display: flex;
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		margin-bottom: 32px;
	}
`;

const NameContainer = styled.div`
	display: flex;
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		flex-direction: column;
	}
	.spacer {
		width: 16px;
		@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
			display: none;
		}
	}
`;

interface PersonalInfoProps {
	firstName: string;
	lastName: string;
	email: string;
	emailReadonly: boolean;
	showValidationErrors: boolean;
	selectedUserType?: UserType;
	institutionOptions: SelectOption[];
	campusOptions: SelectOption[];
	selectedInstitution?: Institution;
	selectedCampus?: Campus;
	level_of_study?: string;
	status?: any;
	selectedOccupation?: string;
	showLoginPrompt: boolean;
	acceptedPrivacyPolicy: boolean;
	acceptedWhatsappCheckbox: boolean;
	onCampusSet(value: boolean): void;
	onLoginClick(): void;
	onDropDownClick(): void;
	onInstitutionSearch(value?: string): void;
	onNoInstitutionFoundClick(): void;
	onPrivacyCheckboxClick(): void;
	onUserTypeSelection(type: UserType): void;
	onPersonalInfoFieldChange(fieldChange: { [fieldName: string]: any }): void;
	onBookingProfileFieldChange(fieldChange: { [fieldName: string]: any }): void;
	onCampusFieldChange(fieldChange: { [fieldName: string]: any }): void;
	onInstitutionFieldChange(
		fieldChange: { [fieldName: string]: any },
		newInstitution?: boolean,
	): void;
	onWhatsappCheckboxClick(): void;
}

const UserTypeInfoMessages = {
	Student: {
		message:
			'Someone studying towards a post-school qualification. This includes a foreign and semester-abroad exchange student.',
		label: 'Student',
	},
	YoungProfessional: {
		message:
			'Anyone not currently pursuing an educational qualification, and is looking for a new home. Includes recent graduates, interns, and young professionals.',
		label: 'Young Professional',
	},
};

export const PersonalInfo = ({
	firstName,
	lastName,
	email,
	emailReadonly,
	showValidationErrors,
	selectedUserType,
	institutionOptions,
	campusOptions,
	selectedInstitution,
	selectedCampus,
	level_of_study,
	selectedOccupation,
	showLoginPrompt,
	acceptedPrivacyPolicy,
	acceptedWhatsappCheckbox,
	onCampusSet,
	onLoginClick,
	onDropDownClick,
	onUserTypeSelection,
	onInstitutionSearch,
	onNoInstitutionFoundClick,
	onPersonalInfoFieldChange,
	onPrivacyCheckboxClick,
	onInstitutionFieldChange,
	onCampusFieldChange,
	onBookingProfileFieldChange,
	onWhatsappCheckboxClick,
}: PersonalInfoProps) => {
	const userTypeOptions = [];

	// Converting UserType to SelectOption
	for (const [key, value] of Object.entries(UserType)) {
		const UserTypeInfoMessageKey = key as keyof typeof UserTypeInfoMessages;
		userTypeOptions.push({
			value: value,
			label: UserTypeInfoMessages[UserTypeInfoMessageKey].label,
		});
	}

	// Get the correct SelectOption based on selected UserType
	const selectedType = userTypeOptions.find(type => type.value === selectedUserType);

	const handleUserTypeSelect = (option: SelectOption) => {
		const selectedUserTypeKey = option.label.split(' ').join('') as keyof typeof UserType;
		onUserTypeSelection(UserType[selectedUserTypeKey]);
	};

	return (
		<>
			<StepTitle>Tell us about yourself</StepTitle>
			<InfoText>All fields are required</InfoText>
			{showLoginPrompt && (
				<LoginPromptContainer>
					<InfoText noMargin>Already have an account?</InfoText>
					<LoginLink onClick={onLoginClick}>Login</LoginLink>
				</LoginPromptContainer>
			)}
			<form>
				<Section>
					<SubTitle>Personal Details</SubTitle>
					<NameContainer>
						<Input
							placeholder="First name (as per ID)"
							onChange={value => onPersonalInfoFieldChange({ firstName: value })}
							value={firstName}
							isErrorState={showValidationErrors && !firstName}
						/>
						<div className="spacer" />
						<Input
							placeholder="Last name (as per ID)"
							onChange={value => {
								onPersonalInfoFieldChange({ lastName: value });
							}}
							value={lastName}
							isErrorState={showValidationErrors && !lastName}
						/>
					</NameContainer>
					<Input
						placeholder="Email"
						onChange={value => onPersonalInfoFieldChange({ email: value })}
						value={email}
						disabled={emailReadonly}
						isErrorState={showValidationErrors && !email}
					/>
					<Select
						placeholder={'Tenant type'}
						options={userTypeOptions}
						selectedOption={selectedType}
						onChange={handleUserTypeSelect}
						isErrorState={showValidationErrors && !selectedType}
					/>
					{selectedType && (
						<>
							<Divider />
						</>
					)}
				</Section>
				{selectedUserType === UserType.Student && (
					<EducationInfo
						institutionOptions={institutionOptions}
						institution={selectedInstitution}
						campus={selectedCampus}
						campusOptions={campusOptions}
						onCampusSet={onCampusSet}
						level_of_study={level_of_study}
						showValidationErrors={showValidationErrors}
						onDropDownClick={onDropDownClick}
						onInstitutionSearch={onInstitutionSearch}
						onFieldChange={onBookingProfileFieldChange}
						onInstitutionFieldChange={onInstitutionFieldChange}
						onCampusFieldChange={onCampusFieldChange}
						onNoInstitutionFoundClick={onNoInstitutionFoundClick}
					/>
				)}
				{selectedUserType === UserType.YoungProfessional && (
					<OccupationInfo
						occupation={selectedOccupation}
						showValidationErrors={showValidationErrors}
						onFieldChange={onBookingProfileFieldChange}
					/>
				)}
				{showLoginPrompt && (
					<>
						<Divider />
						<BoldText>
							In order for DigsConnect to send your information to our landlords, we require your
							consent. Please accept the terms and conditions below.
						</BoldText>
						<PrivacyContainer>
							<Checkbox isPink onClick={onPrivacyCheckboxClick} checked={acceptedPrivacyPolicy}>
								{featureFlagContainer.isEnabled(FeatureFlag.TCsBug) ? (
									<CheckboxText isError={!acceptedPrivacyPolicy && showValidationErrors}>
										I have read and agree to the DigsConnect{' '}
										<StyledLink to={routes.terms_and_conditions}>terms and conditions</StyledLink>{' '}
										and <StyledLink to={routes.privacy}>privacy policy</StyledLink>
									</CheckboxText>
								) : (
									<CheckBoxText>
										I have read and agree to the DigsConnect{' '}
										<StyledLink to={routes.terms_and_conditions}>terms and conditions</StyledLink>{' '}
										and <StyledLink to={routes.privacy}>privacy policy</StyledLink>
									</CheckBoxText>
								)}
							</Checkbox>
						</PrivacyContainer>

						<Checkbox isPink onClick={onWhatsappCheckboxClick} checked={acceptedWhatsappCheckbox}>
							<CheckboxText isError={!acceptedWhatsappCheckbox && showValidationErrors}>
								I consent to be contacted via WhatsApp
							</CheckboxText>
						</Checkbox>
					</>
				)}
			</form>
		</>
	);
};
