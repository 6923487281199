import React from 'react';

import { SubTitle } from '../../styled-subcomponents/SubTitle';
import { UserType, FundingType, LeaseType } from 'models/listing';
import { Select, SelectOption } from 'lib/Select';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { RoomCarousel } from 'components/RoomCarousel';
import { SingleRoomCard } from 'components/SingleRoomCard';
import { Currency, Listing, Room } from 'models/listing';
import { LeasePeriod } from './LeasePeriod';
import { StepTitle } from '../../styled-subcomponents/StepTitle';
import { InfoText } from '../../styled-subcomponents/InfoText';
import { Divider } from 'lib/Divider';

const fundingTypes: SelectOption[] = [
	{
		value: FundingType.Private,
		label: 'I, or a relative of mine, will be paying for my accommodation.',
	},
	{
		value: FundingType.Nsfas,
		label: 'NSFAS will be paying for my accommodation.',
	},
	{
		value: FundingType.Bursary,
		label: 'I have been given a bursary or grant for my accommodation.',
	},
	{
		value: FundingType.Organisation,
		label: 'A organisation is funding my accommodation.',
	},
];

interface FundingInfoProps {
	userType?: UserType;
	selectedFundingType?: FundingType;
	rooms: Room[];
	selectedRoomId: string;
	currency: Currency;
	listing: Listing;
	preferredLeasePeriodMonths: number;
	leasePeriodMonths: number;
	showValidationErrors: boolean;
	preferredMoveInDate: string;
	moveInDate: string;
	remainingBookingSlots?: number;
	onFundingInfoSelect(type: FundingType): void;
	onRoomSelect(type: Room): void;
	onDateChange(date: string): void;
	onLeasePeriodChange(months: number): void;
	onDateElementClick(): void;
}

export const FundingInfo = ({
	rooms,
	listing,
	currency,
	userType,
	moveInDate,
	selectedRoomId,
	leasePeriodMonths,
	preferredMoveInDate,
	selectedFundingType,
	showValidationErrors,
	preferredLeasePeriodMonths,
	onRoomSelect,
	onDateChange,
	onDateElementClick,
	onLeasePeriodChange,
	onFundingInfoSelect,
}: FundingInfoProps) => {
	const getFundingTypes = () => {
		return fundingTypes.filter(
			fundingType =>
				(userType === UserType.YoungProfessional &&
					fundingType.value !== FundingType.Nsfas &&
					fundingType.value !== FundingType.Bursary) ||
				(userType === UserType.Student && fundingType.value !== FundingType.Organisation),
		);
	};

	const selectedType: any = fundingTypes.find(type => type.value === selectedFundingType);

	const handleFundingInfoSelect = (option: SelectOption) => {
		onFundingInfoSelect(option.value);
	};

	const screenWidth = useScreenWidth();

	return (
		<form>
			<StepTitle>Leasing arrangements</StepTitle>
			<InfoText>All fields are required</InfoText>
			<SubTitle>Accommodation financing</SubTitle>
			<Select
				placeholder={'Who is handling your rent'}
				options={getFundingTypes()}
				selectedOption={selectedType}
				onChange={handleFundingInfoSelect}
				isErrorState={showValidationErrors && !selectedType}
			/>
			{listing.lease_type === LeaseType.SingleRoom && (
				<RoomCarousel
					rooms={(rooms || []).filter(room => room.active || listing.external)}
					selectedRoomId={selectedRoomId}
					onRoomClick={onRoomSelect}
					iconSize={18}
					maxHorizontalCards={2}
					iconTop={80}
					horizontalPadding={screenWidth >= BREAKPOINT_MOBILE_PX ? 16 : 8}
					CardComponent={SingleRoomCard}
					currency={currency}
					listing={listing}
					carouselTitle={'Which room do you prefer?'}
					selectable
				/>
			)}
			<Divider />
			<LeasePeriod
				preferredMoveInDate={preferredMoveInDate}
				moveInDate={moveInDate}
				onDateChange={onDateChange}
				preferredLeasePeriodMonths={preferredLeasePeriodMonths}
				leasePeriodMonths={leasePeriodMonths}
				onLeasePeriodChange={onLeasePeriodChange}
				showValidationErrors={showValidationErrors}
				onDateElementClick={onDateElementClick}
			/>
		</form>
	);
};
